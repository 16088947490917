var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c(
        "div",
        { staticClass: "register-header shadow-border-bottom" },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/logo.png"), alt: "" },
          }),
          _c(
            "el-button",
            {
              staticStyle: { padding: "8px 35px" },
              attrs: { plain: "", round: "", size: "meduim" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "login" })
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("loginImmediately")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "register-body" }, [
        _c("h1", { staticClass: "big-title" }, [
          _c("span", [_vm._v("Welcome to Bunkerchain")]),
          _c("em", {
            staticClass: "close-icon el-icon-close",
            on: {
              click: function ($event) {
                return _vm.handleCancelRegisterCompany()
              },
            },
          }),
        ]),
        _c("h2", { staticClass: "small-title" }, [
          _vm._v(_vm._s(_vm.$t("enterpriseRegister"))),
        ]),
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "el-form",
              {
                ref: "companyInfoForm",
                attrs: {
                  rules: _vm.companyInfoRules,
                  model: _vm.companyInfoFormData,
                  "validate-on-rule-change": false,
                  "label-position": "top",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-block" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("companyInfo"))),
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Physical Supplier",
                                  prop: "licenseType",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { padding: "0 12px" } },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-color": "#8182ff",
                                        "active-text": "Yes",
                                        "inactive-text": "No",
                                      },
                                      on: {
                                        change: _vm.handleLicenseTypeChange,
                                      },
                                      model: {
                                        value:
                                          _vm.companyInfoFormData.licenseType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.companyInfoFormData,
                                            "licenseType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "companyInfoFormData.licenseType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "License No.",
                                  prop: "licenseNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "input-with-select",
                                  attrs: {
                                    disabled:
                                      !_vm.companyInfoFormData.licenseType,
                                  },
                                  model: {
                                    value:
                                      _vm.companyInfoFormData.licenseNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "licenseNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.licenseNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("companyName"),
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  on: { change: _vm.handleCompanyNameChange },
                                  model: {
                                    value: _vm.companyInfoFormData.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "companyName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Company Abbreviation(3 to 6 letters)",
                                  prop: "abbreviation",
                                },
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "uppercase",
                                      rawName: "v-uppercase:true",
                                      arg: "true",
                                    },
                                  ],
                                  model: {
                                    value: _vm.companyInfoFormData.abbreviation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "abbreviation",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.abbreviation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("businessIncorporationNumber"),
                                  prop: "businessIncorporationNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  on: { change: _vm.handleCompanyUENChange },
                                  model: {
                                    value:
                                      _vm.companyInfoFormData
                                        .businessIncorporationNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "businessIncorporationNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.businessIncorporationNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "GST Registration No.",
                                  prop: "gstRegistrationNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value:
                                      _vm.companyInfoFormData.gstRegistrationNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "gstRegistrationNo",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.gstRegistrationNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("incorporationDate"),
                                  prop: "incorporationDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "dd-MM-yyyy",
                                    "value-format": "yyyy-MM-dd 00:00:01",
                                    placeholder: _vm.$t("selectDate"),
                                  },
                                  model: {
                                    value:
                                      _vm.companyInfoFormData.incorporationDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "incorporationDate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.incorporationDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("companyType"),
                                  prop: "companyType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    model: {
                                      value:
                                        _vm.companyInfoFormData.companyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.companyInfoFormData,
                                          "companyType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "companyInfoFormData.companyType",
                                    },
                                  },
                                  _vm._l(_vm.companyOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.key,
                                      attrs: {
                                        label: item.value,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("incorporationCountry"),
                                  prop: "incorporationCountry",
                                },
                              },
                              [
                                _c("country-selector", {
                                  staticStyle: { width: "100%" },
                                  model: {
                                    value:
                                      _vm.companyInfoFormData
                                        .incorporationCountry,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "incorporationCountry",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.incorporationCountry",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("streetName"),
                                  prop: "streetName",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.companyInfoFormData.streetName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "streetName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.streetName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("buildingName"),
                                  prop: "buildingName",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.companyInfoFormData.buildingName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "buildingName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.buildingName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("unitNumber"),
                                  prop: "unitNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.companyInfoFormData.unitNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "unitNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "companyInfoFormData.unitNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 6, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("postal"),
                                  prop: "postal",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.companyInfoFormData.postal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "postal",
                                        $$v
                                      )
                                    },
                                    expression: "companyInfoFormData.postal",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 3 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("fax"), prop: "fax" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.companyInfoFormData.fax,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.companyInfoFormData,
                                        "fax",
                                        $$v
                                      )
                                    },
                                    expression: "companyInfoFormData.fax",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    ref: "adminInfoForm",
                    attrs: {
                      rules: _vm.adminInfoRules,
                      model: _vm.adminInfoFormData,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-block" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("adminInfo"))),
                        ]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("email"),
                                      prop: "email",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "double-column" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled:
                                              _vm.emailVerificationPassed ||
                                              !!_vm.uuid,
                                          },
                                          model: {
                                            value: _vm.adminInfoFormData.email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.adminInfoFormData,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "adminInfoFormData.email",
                                          },
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              border: "0",
                                            },
                                            attrs: {
                                              round: "",
                                              type: "primary",
                                              plain: "",
                                              disabled:
                                                _vm.emailVerificationPassed,
                                              loading: _vm.loading,
                                            },
                                            on: {
                                              click: _vm.getVerificationCode,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.emailVerificationPassed
                                                      ? "emailVerified"
                                                      : "clickVerifyEmail"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            !this.emailExist
                              ? [
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, md: 8, lg: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("firstName"),
                                            prop: "firstName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData.firstName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "firstName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.firstName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, md: 8, lg: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lastName"),
                                            prop: "lastName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData.lastName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "lastName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.lastName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 6, md: 8, lg: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("password"),
                                            prop: "password",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                              "show-password": "",
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData.password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 6, md: 8, lg: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("duplicatePassword"),
                                            prop: "duplicatePassword",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                              "show-password": "",
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData
                                                  .duplicatePassword,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "duplicatePassword",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.duplicatePassword",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, md: 8, lg: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("homePhoneNumber"),
                                            prop: "homePhoneNumber",
                                          },
                                        },
                                        [
                                          _c("phone-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData
                                                  .homePhoneNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "homePhoneNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.homePhoneNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, md: 8, lg: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("officePhoneNumber"),
                                            prop: "officePhoneNumber",
                                          },
                                        },
                                        [
                                          _c("phone-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData
                                                  .officePhoneNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "officePhoneNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.officePhoneNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, md: 8, lg: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("designation"),
                                            prop: "designation",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                !_vm.emailVerificationPassed,
                                            },
                                            model: {
                                              value:
                                                _vm.adminInfoFormData
                                                  .designation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.adminInfoFormData,
                                                  "designation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "adminInfoFormData.designation",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { sm: 12, md: 8, lg: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("salutation"),
                                            prop: "salutation",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled:
                                                  !_vm.emailVerificationPassed,
                                              },
                                              model: {
                                                value:
                                                  _vm.adminInfoFormData
                                                    .salutation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.adminInfoFormData,
                                                    "salutation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "adminInfoFormData.salutation",
                                              },
                                            },
                                            _vm._l(
                                              [
                                                "Mr.",
                                                "Mrs.",
                                                "Miss",
                                                "Dr.",
                                                "Ms.",
                                                "Prof.",
                                                "Rev.",
                                                "Lady.",
                                                "Sir.",
                                                "Capt.",
                                              ],
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: { value: item },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.companyInfoFormData.companyType ===
                _vm.COMPANY_TYPE.FINANCIAL_SERVICE_PROVIDER
                  ? _c("financer-register", { ref: "financerInfo" })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "form-block document-upload" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("companyDocument"))),
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _vm._l(_vm.fileFields.slice(0, 3), function (item) {
                          return _c(
                            "el-col",
                            { key: item, attrs: { sm: 12, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t(item), prop: item } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "upload-column" },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.fileUploadUrl,
                                            accept:
                                              item === "bdnLogo"
                                                ? ".jpg,.png"
                                                : ".pdf",
                                            limit: 1,
                                            "file-list":
                                              _vm.companyInfoFormData[item],
                                            "on-success":
                                              _vm.handleFileUploadSuccess(
                                                item,
                                                "single"
                                              ),
                                            "before-remove":
                                              _vm.handleBeforeRemove,
                                            "on-remove": _vm.handleRemove(item),
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "upload-btn",
                                              attrs: {
                                                type: _vm.companyInfoFormData[
                                                  item
                                                ].length
                                                  ? "danger"
                                                  : "primary",
                                                disabled:
                                                  !!_vm.companyInfoFormData[
                                                    item
                                                  ].length,
                                                plain: "",
                                                round: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.companyInfoFormData[
                                                      item
                                                    ].length
                                                      ? _vm.$t("uploaded")
                                                      : item === "bdnLogo"
                                                      ? _vm.$t("uploadImage")
                                                      : _vm.$t("uploadFile")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.companyInfoFormData[
                                                      item
                                                    ].length
                                                      ? _vm.$t(
                                                          "uploadedAndCanDelete"
                                                        )
                                                      : item === "bdnLogo"
                                                      ? _vm.$t(
                                                          "uploadAttachmentPic"
                                                        )
                                                      : _vm.$t(
                                                          "uploadAttachmentPDF"
                                                        )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        _vm._l(_vm.fileFields.slice(3, 5), function (item) {
                          return _c(
                            "el-col",
                            { key: item, attrs: { sm: 12, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t(item), prop: item } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "upload-column" },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.fileUploadUrl,
                                            accept:
                                              item === "bdnLogo"
                                                ? ".jpg,.png"
                                                : ".pdf",
                                            limit: 1,
                                            "file-list":
                                              _vm.companyInfoFormData[item],
                                            "on-success":
                                              _vm.handleFileUploadSuccess(
                                                item,
                                                "single"
                                              ),
                                            "before-remove":
                                              _vm.handleBeforeRemove,
                                            "on-remove": _vm.handleRemove(item),
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "upload-btn",
                                              attrs: {
                                                type: _vm.companyInfoFormData[
                                                  item
                                                ].length
                                                  ? "danger"
                                                  : "primary",
                                                disabled:
                                                  !!_vm.companyInfoFormData[
                                                    item
                                                  ].length,
                                                plain: "",
                                                round: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.companyInfoFormData[
                                                      item
                                                    ].length
                                                      ? _vm.$t("uploaded")
                                                      : item === "bdnLogo"
                                                      ? _vm.$t("uploadImage")
                                                      : _vm.$t("uploadFile")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.companyInfoFormData[
                                                      item
                                                    ].length
                                                      ? _vm.$t(
                                                          "uploadedAndCanDelete"
                                                        )
                                                      : item === "bdnLogo"
                                                      ? _vm.$t(
                                                          "uploadAttachmentPic"
                                                        )
                                                      : _vm.$t(
                                                          "uploadAttachmentPDF"
                                                        )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "register-foot" }, [
        _c(
          "div",
          { staticClass: "check-bar" },
          [
            _c("el-checkbox", {
              staticClass: "checkbox",
              model: {
                value: _vm.agreeProtocol,
                callback: function ($$v) {
                  _vm.agreeProtocol = $$v
                },
                expression: "agreeProtocol",
              },
            }),
            _c("span", { staticStyle: { color: "#606266" } }, [
              _vm._v(" I have read and agree "),
            ]),
            _vm._m(0),
            _vm._v(" & "),
            _vm._m(1),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-group" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  round: "",
                  loading: _vm.submitLoading,
                  disabled: !_vm.emailVerificationPassed || !_vm.agreeProtocol,
                },
                on: { click: _vm.register },
              },
              [_vm._v(" " + _vm._s(_vm.$t("register")) + " ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "login" })
                  },
                },
              },
              [_vm._v(" Close ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.codeVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c("p", [
                _c("em", { staticClass: "el-icon-info" }),
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.$t("verifyTipFront")} ${
                        _vm.adminInfoFormData.email
                      } ${_vm.$t("verifyTipEnd")}`
                    ) +
                    " "
                ),
              ]),
              _c("el-input", {
                attrs: { placeholder: _vm.$t("verificationCode") },
                model: {
                  value: _vm.verificationCode,
                  callback: function ($$v) {
                    _vm.verificationCode = $$v
                  },
                  expression: "verificationCode",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.cancelVerifyEmail },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.verifyEmailCode },
                },
                [_vm._v(" " + _vm._s(_vm.$t("verify")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-company-agreement",
          attrs: { visible: _vm.protocolVisible, fullscreen: "", width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.protocolVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px 30px 30px" } },
            [
              _c("h2", { staticStyle: { "text-align": "center" } }, [
                _vm._v("Corporate Registration Agreement of Bunkerchain"),
              ]),
              _c("P", [_vm._v("........")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.registerSuccessVisible,
            width: "660px",
            top: "25vh",
            "close-on-click-modal": false,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.registerSuccessVisible = $event
            },
            close: function ($event) {
              return _vm.$router.push({ name: "login" })
            },
          },
        },
        [
          _c("div", { staticClass: "register-success-text" }, [
            _vm._v(
              "Your registration has been submitted and is pending for approval."
            ),
          ]),
          _c("div", { staticClass: "register-success-text" }, [
            _vm._v(
              "Please check your registered email for the approval outcome."
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.registerSuccessVisible = false
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "a",
        { attrs: { href: "/documents/terms-of-use.pdf", target: "_blank" } },
        [_vm._v("Terms of Use")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "a",
        { attrs: { href: "/documents/privacy-policy.pdf", target: "_blank" } },
        [_vm._v("Privacy Policy")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }